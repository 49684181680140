import { SimpleShowLayout, Show } from "react-admin";

import { OpenSearchTitle } from './components'

const OpenSearchShow = (props) => {
  return (
    <Show title={<OpenSearchTitle />} {...props}>
      <SimpleShowLayout>
        {/* <Box component="div" sx={{ display: 'inline-block', pt: 1 }}>
          <ServiceNameField label="Service Name" className={classes.inline} />
          <BookingStatusField  className={classes.inline} />
        </Box>
        <ConditionalField comp={TextField} label="Title" source="title" />
        <ReferenceField label="Customer" link="show" source="customer.id" reference="Customer">
          <UserNameField />
        </ReferenceField>
        <ReferenceField label="Provider" link="show" source="provider.id" reference="Provider">
          <UserNameField />
        </ReferenceField>

        <StartDateField label="Start" />
        <EndDateField label="End" />
        <ConditionalField comp={TextField} label="Shared Notes (visible to customer and provider)" source="notes" />
        <ConditionalField comp={TextField} label="Dial-in Info" source="dialInInfo" />
        <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} label="Hourly Price Override" source="hourlyPrice" />
        <ConditionalField comp={TextField} label="Payment Method" source="paymentMethod" />

        <RecurrenceInfo addLabel={true} label="Recurrence Info" />
        <ModificationInfo /> */}
      </SimpleShowLayout>
    </Show>
  );
};

export default OpenSearchShow;
