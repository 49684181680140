import React from "react";
// import moment from 'moment';
// import { Card, CardContent } from "@mui/material";

// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import Timer from "@mui/icons-material/Timer";

import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  ReferenceField,
  Filter,
  SelectInput,
//   ReferenceInput,
//   AutocompleteInput,
//   FilterList,
//   FilterListItem,
//   DateInput,
//   NumberField,
//   DateField,
} from "react-admin";

// import {
//   endOfYesterday,
//   startOfWeek,
//   endOfWeek,
//   startOfMonth,
//   endOfMonth,
//   subMonths,
//   addMonths,
//   format,
// } from "date-fns";

import UserNameField from "../../components/user-name-field";
import ServiceNameField from '../../components/service-name-field';
// import BookingStatusField from '../../components/booking-status-field';
// import StartDateField from '../../components/start-date-field';
// import EndDateField from '../../components/end-date-field';
import PaginationOptions from '../../components/pagination-options';
import { serviceNameList } from '../../utils';

// const getOptionText = (r) => {
//   if (!r || !r?.id) return null;
//   let name = r.firstName;
//   if (r.lastName) name += ` ${r.lastName}`;
//   return name;
// };

const OpenSearchListFilter = (props) => {
  return (
    <Filter {...props}>
      
       <SelectInput source="serviceName" choices={[...serviceNameList]} label="Service Type" alwaysOn />
    </Filter>
  );
};

// <ReferenceInput
//   label="Customer"
//   source="customerId"
//   filter={{ userRole: "customer" }}
//   sort={{ field: "firstName", order: "DESC" }}
//   reference="Customer"
//   filterToQuery={(query) => ({ query })}
//   allowEmpty
//   alwaysOn
// >
//   <AutocompleteInput
//     shouldRenderSuggestions={(val) => val.length >= 3}
//     optionText={getOptionText}
//   />
// </ReferenceInput>
// <ReferenceInput
//   label="Provider"
//   source="providerId"
//   filter={{ userRole: "provider" }}
//   sort={{ field: "firstName", order: "DESC" }}
//   reference="Provider"
//   filterToQuery={(query) => ({ query })}
//   allowEmpty
//   alwaysOn
// >
//   <AutocompleteInput
//     shouldRenderSuggestions={(val) => val.length >= 3}
//     optionText={getOptionText}
//   />
// </ReferenceInput>

// const StatusFilter = () => (
//   <FilterList label="Status" icon={<Timer />}>
//     <FilterListItem
//       label="Pending"
//       value={{ isPendingConfirmation: true }}
//     />
//   </FilterList>
// );


// const StartDateFilter = () => (
//   <FilterList label="Date" icon={<AccessTimeIcon />}>
//     <FilterListItem
//       label="Today"
//       value={{
//         startDate_gte: format(endOfYesterday(), DATE_FORMAT),
//         startDate_lte: format(new Date(), DATE_FORMAT),
//       }}
//     />
//     <FilterListItem
//       label="This week"
//       value={{
//         startDate_gte: format(startOfWeek(new Date()), DATE_FORMAT),
//         startDate_lte: format(endOfWeek(new Date()), DATE_FORMAT),
//       }}
//     />
//     <FilterListItem
//       label="This month"
//       value={{
//         startDate_gte: format(startOfMonth(new Date()), DATE_FORMAT),
//         startDate_lte: format(endOfMonth(new Date()), DATE_FORMAT),
//       }}
//     />
//     <FilterListItem
//       label="Last month"
//       value={{
//         startDate_gte: format(
//           subMonths(startOfMonth(new Date()), 1),
//           DATE_FORMAT
//         ),
//         startDate_lte: format(startOfMonth(new Date()), DATE_FORMAT),
//       }}
//     />
//     <FilterListItem
//       label="Next month"
//       value={{
//         startDate_gte: format(endOfMonth(new Date()), DATE_FORMAT),
//         startDate_lte: format(
//           addMonths(endOfMonth(new Date()), 1),
//           DATE_FORMAT
//         ),
//       }}
//     />
//   </FilterList>
// );

// const EventSideBar = () => (
//   <div style={{ marginLeft: '16px' }}>
//     <Card>
//       <CardContent>
//         <StatusFilter />
//         <StartDateFilter />
//       </CardContent>
//     </Card>
//   </div>
// );

const OpenSearchList = (props) => {
  return (
    <List
      {...props}
      pagination={<PaginationOptions />}
      perPage={25}
      title="Open Searches"
      filters={<OpenSearchListFilter />}
      // sort={{ field: 'createdTimestamp', order: 'DESC' }}
      filterDefaultValues={{
      //   'eventType': 'appointment',
      //   'startDate_gte': moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
      //   'startDate_lte': moment().add(3, 'months').format('YYYY-MM-DD'),
      }}
    >
      <Datagrid rowClick="show">
        <ReferenceField label="Customer" source="customerId" reference="Customer">
          <UserNameField />
        </ReferenceField>
        <ServiceNameField label="Service" />
        <TextField label="State" source="address.state" />
        <TextField label="Location" source="address.zipcode" />
        <TextField label="Start Date" source="startDate" />
        <TextField label="End Date" source="endDate" />
        <FunctionField
          source="time"
          render={record => `${record.startTime} - ${record.endTime}`}
        />
        <TextField label="Children" source="offsprings" />
        <FunctionField
            label="Performed On"
            render={({ performedOn }) => performedOn
                ? new Date(performedOn).toDateString()
                : "-"
            }
          />
      </Datagrid>
    </List>
  );
};

export default OpenSearchList;
