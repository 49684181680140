import {
  Tab,
  TextField,
  BooleanField,
  FunctionField,
  ShowContextProvider,
  useRecordContext,
  Labeled,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { Divider, Stack } from "@mui/material";

import { LanguageNameArrayField, AvailabilityField} from "../components";
import { OperationRadiusField, GeolocationField } from "../../../../components";

const styles = {
  inline: { display: "inline-block" },
  chip: { marginRight: 3 },
};
const useStyles = makeStyles(styles);

const TabMatchingCriteria = (props) => {
  const record = useRecordContext();
  const classes = useStyles();

  return (
    <Tab label="Matching Criteria" {...props}>
      <ShowContextProvider value={{ record }}>
        <Stack sx={{ display: "flex", flexDirection: "row" }}>
          <Stack sx={{ flexGrow: 1, gap: "8px", alignItems: "flex-start" }}>
            <Labeled label="Dogs OK?">
              <BooleanField source="allowsDogs" className={classes.inline} />
            </Labeled>

            <Labeled label="Cats OK?">
              <BooleanField source="allowsCats" className={classes.inline} />
            </Labeled>

            <Labeled label="Smoking OK?">
              <BooleanField source="allowsSmoking" className={classes.inline} />
            </Labeled>

            <Labeled label="Has Car?">
              <BooleanField source="hasCar" className={classes.inline} />
            </Labeled>

            <Labeled label="Has Special Needs Experience">
              <BooleanField
                source="hasSpecialNeedsExperience"
                className={classes.inline}
              />
            </Labeled>

            <Labeled label="Minimum Age">
              <TextField
                source="childcareAgeRange.min"
                className={classes.inline}
              />
            </Labeled>

            <Labeled label="Maximum Age">
              <TextField
                source="childcareAgeRange.max"
                className={classes.inline}
              />
            </Labeled>

            <Labeled label="Speaks Languages">
              <LanguageNameArrayField source="speaksLanguages" />
            </Labeled>

            <Labeled label="Reliability Score">
              <TextField source="reliabilityScore" className={classes.inline} />
            </Labeled>
          </Stack>

          <Stack sx={{ flexGrow: 1, gap: "8px", alignItems: "flex-start" }}>
            <Labeled label="Zipcode">
              <TextField source="address.zipcode" />
            </Labeled>

            <Labeled label="State">
              <TextField source="address.state" />
            </Labeled>

            <Labeled label="Commute time">
              <FunctionField
                render={({ desirableCommutingTime }) =>
                  desirableCommutingTime
                    ? `${desirableCommutingTime} minutes`
                    : "-"
                }
              />
            </Labeled>

            <OperationRadiusField />
            <GeolocationField />

            <Labeled label="Geolocation Link">
              <FunctionField
                render={({ geoLocation }) =>
                  geoLocation ? (
                    <a
                      target="blank"
                      href={`https://maps.google.com/?q=${geoLocation.lat},${geoLocation.lng}`}
                    >
                      See on the map
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
            </Labeled>
          </Stack>
        </Stack>

        <Divider sx={{ margin: '8px 0'}} />

        <Stack>
          <Labeled label="Availability">
              <AvailabilityField />
          </Labeled>
        </Stack>
      </ShowContextProvider>
    </Tab>
  );
};

export default TabMatchingCriteria;
