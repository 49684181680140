import {
  Tab,
  TextField,
  DateField,
  ShowButton,
  ReferenceField,
  Pagination,
  ReferenceManyField,
  Datagrid,
} from "react-admin";

import { UserNameField, ServiceNameField, BookingStatusField } from "../../../../components";

const TabSessions = (props) => (
  <Tab label="Sessions" {...props}>
    <ReferenceManyField
      pagination={<Pagination />}
      reference="EventInstance"
      target="providerId"
      addLabel={false}
      sort={{ field: "createdTimestamp", order: "DESC" }}
    >
      <Datagrid>
        <ReferenceField
          sortable={false}
          label="Customer"
          source="customer.id"
          reference="Customer"
          link="show"
        >
          <UserNameField />
        </ReferenceField>
        <TextField sortable={false} source="startDate" />
        <TextField sortable={false} source="startTime" />
        <TextField sortable={false} source="endDate" />
        <TextField sortable={false} source="endTime" />
        <DateField sortable={false} source="createdTimestamp" label="created" />
        <ServiceNameField sortable={false} />
        <BookingStatusField sortable={false} />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>
  </Tab>
);

export default TabSessions;
