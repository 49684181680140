import moment from 'moment';
import { useRecordContext } from "react-admin";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";

import LanguageNameField from '../../../../components/language-name-field';
import { FlattenedArrayField } from '../../../../components';
import { WEEKDAYS } from '../../../../shared/constants';

const { REACT_APP_WEB_URL: webUrl } = process.env;
const styles = {
  inline: { display: "inline-block" },
  chip: { marginRight: 3 },
};
const useStyles = makeStyles(styles);

export const DirectLink = ({ comp, path }) => {
  const record = useRecordContext();
  const link = `${webUrl}/user/${record.alias}${path ? `/${path}` : ""}`;
  return <a href={link}>{link}</a>;
};


export const LanguageNameArrayField = (props) => {
  const record = useRecordContext()
  const classes = useStyles();

  if(record.speaksLanguages === undefined) {
    record.speaksLanguages = ['en']
  }

  return (<FlattenedArrayField singleComp={(item) => <LanguageNameField className={classes.chip} key={item} languageName={item} />} { ...props} addLabel />)
}

export const AttachedLink = (props) => {
  const record = useRecordContext();
  const url = record[props.source];

  return (
    <a href={url} target="_blank">
      Open
    </a>
  );
};

export const DebugField = () => {
  const record = useRecordContext();
  const input = {
    ...record,
    eventInstances: undefined,
    eventInstancesIds: undefined
  }
  
  return <pre style={{ fontSize: 12 }}>{JSON.stringify(input, null, 2)}</pre>;
};

export const AvailabilityField = () => {
  const { regularSchedule } = useRecordContext();
  const regularScheduleMap = (regularSchedule ?? []).reduce((acc, cur) => ({ ...acc, [cur.weekday]: cur }), {});

  const Day = ({ weekday }) => {
    const day = regularScheduleMap[weekday];

    if (!day) return `${weekday}: off`;

    if (day.allDay) return `${weekday}: All day`;

    return `${weekday}: ${moment(day.startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(day.endTime, 'HH:mm:ss').format('HH:mm')}`;

    
  };

  if (!regularSchedule) {
    return '-';
  }
  
  return (
    <Stack sx={{ gap: '4px' }}>
      {WEEKDAYS.map(weekday => (
        <div key={weekday}>
          <Day weekday={weekday} />
        </div>
      ))}
    </Stack>
  );
};
