import { gql } from '@apollo/client';
import buildGraphqlProvider, { buildQuery } from 'ra-data-graphql-simple'
import graphQLClient from './graphql-client'

const pageSize = 1000
const _getListSymbol = Symbol();

// function dataProviderDecorator(dataProvider) {
//   dataProvider[_getListSymbol] = dataProvider.getList

//   dataProvider.getList = function(resource, params) {
//     if (params?.pagination?.perPage > pageSize) {
//       return new Promise(async (resolve, reject) => {
//         const bulkResult = [];

//         const getPageOfData = async (page) => {
//           try {
//             const result = await this[_getListSymbol](resource, {
//               ...params,
//               pagination: { page, perPage: pageSize },
//             });

//             bulkResult.push(...result.data);

//             if (result.data.length >= pageSize) {
//               await getPageOfData(page + 1);
//             }
//           } catch (err) {
//             return reject(err);
//           }
//         }

//         await getPageOfData(1);

//         resolve({
//           data: bulkResult,
//           total: bulkResult.length,
//         });
//       });
//     } else {
//       return this[_getListSymbol](resource, params)
//     }
//   }


//   return dataProvider
// }

// const customBuildQuery = introspection => (fetchType, resource, params) => {
//   const builtQuery = buildQuery(introspection)(fetchType, resource, params);

//   console.log('resource, fetchType', builtQuery, resource, fetchType);
//   // if (resource === "Command" && fetchType === "GET_ONE") {
//   //   return {
//   //     // Use the default query variables and parseResponse
//   //     ...builtQuery,
//   //     // Override the query
//   //     query: gql`
//   //       query Command($id: ID!) {
//   //         data: Command(id: $id) {
//   //           id
//   //           reference
//   //           customer {
//   //             id
//   //             firstName
//   //             lastName
//   //           }
//   //         }
//   //       }
//   //     `,
//   //   };
//   // }

//   return builtQuery;
// };

const graphqlDataProvider = buildGraphqlProvider({
  client: graphQLClient,
  // buildQuery: customBuildQuery,
  introspection: {},
});

graphqlDataProvider.promoteProviders = async (ids) => {
  console.log('RUN', ids)
  return await graphQLClient.mutate({
    mutation: gql`
      mutation promoteProviders($ids: [Int]) {
        promoteProviders(ids: $ids) {
          debug
          matches {
            matchingCriteria {
              customer {
                email
                shortUserId
                firstName
                lastName
              }
              serviceName
              restrictToProviders
              numDogs
              numCats
              children {
                birthdayTimestamp
              }
              specialNeeds
              geoLocation {
                lng
                lng
              }
              enrichmentActivities
            }
            providers {
              shortUserId
              email
              firstName
              lastName
            }
          }
        }
      }
    `,
    variables: { ids },
  });
};

export default graphqlDataProvider;