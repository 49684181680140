import EventEdit from "./edit";
import OpenSearchList from "./list";
import OpenSearchShow from "./show";
import EventCreate from './create';

const openSearchComponents = {
  list: OpenSearchList,
  show: OpenSearchShow,
  edit: EventEdit,
  create: EventCreate,
};

export default openSearchComponents;
